<template>
  <div class="push">
    <v-btn @click="pushMoreData">Add Data</v-btn>
  </div>
</template>

<script>
export default {
  name: "device",
  data() {
    return {
      sensors: [
        {
          id: "1",
          f1: "100",
          f2: "300",
          f3: "100",
          f4: "100",
          f5: "100",
          f6: "299593"
        },
        {
          id: "1",
          f1: "200",
          f2: "350",
          f3: "100",
          f4: "100",
          f5: "100",
          f6: "299593"
        },
        {
          id: "1",
          f1: "50",
          f2: "300",
          f3: "100",
          f4: "100",
          f5: "100",
          f6: "299593"
        },
        {
          id: "1",
          f1: "100",
          f2: "100",
          f3: "100",
          f4: "100",
          f5: "100",
          f6: "299593"
        },
        {
          id: "1",
          f1: "500",
          f2: "500",
          f3: "100",
          f4: "100",
          f5: "100",
          f6: "0"
        },
        {
          id: "1",
          f1: "300",
          f2: "200",
          f3: "100",
          f4: "100",
          f5: "100",
          f6: "0"
        }
      ]
    };
  },
  components: {},
  async created() {},
  computed: {},
  methods: {
    async pushData(sensor) {
      let bin = this.dec2Bin(Number(sensor.f6), 20);
      try {
        let newSensor = await this.addReading(sensor, bin);
        console.log(newSensor);
        return newSensor;
      } catch (error) {
        return error;
      }
    },
    async delay(sec) {
      return new Promise(res => {
        setTimeout(() => {
          res();
        }, sec);
      });
    },
    async pushMoreData() {
      for (let sensor of this.sensors) {
        await this.pushData(sensor);
        await this.delay(2000);
      }
    },
    dec2Bin(decimal, length) {
      return length
        ? decimal.toString(2).padStart(length, "0")
        : decimal.toString(2);
    },
    async addSensor(sensor, bin) {
      try {
        let { data } = await this.axios.post(
          "https://ij2apwwihnexxbt6q6iyu3l4xe.appsync-api.us-east-1.amazonaws.com/graphql",
          {
            query: `mutation CreateSensor($input: CreateSensorInput!) {
      createSensor(input: $input) {
        deviceId
        createdAt
        barrel1
        barrel2
        compSuction
        compReturn
        ambient
        b1Defrost
        b1Liquid
        computerCoil
        b1Water
        b1Syrup
        b1C02
        b2Defrost
        b2Liquid
        b2Water
        b2Syrup
        b2C02
        highPressureCoil
        b1SyrupOut
        b1BIB
        b1Fill
        C02Pressure
        b2SyrupOut
        b2BIB
        b2Fill
        h20Pressure
      }
    }`,
            variables: {
              input: {
                deviceId: sensor.id,
                createdAt: new Date().toISOString(),
                barrel1: sensor.f1,
                barrel2: sensor.f2,
                compSuction: sensor.f3,
                compReturn: sensor.f4,
                ambient: sensor.f5,
                b1Defrost: bin.charAt(0),
                b1Liquid: bin.charAt(1),
                computerCoil: bin.charAt(2),
                b1Water: bin.charAt(3),
                b1Syrup: bin.charAt(4),
                b1C02: bin.charAt(5),
                b2Defrost: bin.charAt(6),
                b2Liquid: bin.charAt(7),
                b2Water: bin.charAt(8),
                b2Syrup: bin.charAt(9),
                b2C02: bin.charAt(10),
                highPressureCoil: bin.charAt(11),
                b1SyrupOut: bin.charAt(12),
                b1BIB: bin.charAt(13),
                b1Fill: bin.charAt(14),
                C02Pressure: bin.charAt(15),
                b2SyrupOut: bin.charAt(16),
                b2BIB: bin.charAt(17),
                b2Fill: bin.charAt(18),
                h20Pressure: bin.charAt(19)
              }
            }
          },
          {
            headers: {
              "x-api-key": "da2-xhtz2wrgsbe27cafmgcr37vaj4"
            }
          }
        );
        return data.data.createSensor;
      } catch (error) {
        return {
          statusCode: 400,
          body: error
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>