import { render, staticRenderFns } from "./Push.vue?vue&type=template&id=5e62e7c1&scoped=true&"
import script from "./Push.vue?vue&type=script&lang=js&"
export * from "./Push.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e62e7c1",
  null
  
)

export default component.exports